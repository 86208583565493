<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Proveedores</h1>
		</div>
		<br />

		<DataGrid :config="dataGridConfig" :data="proveedores" :select="seleccionar" @actions="dataGridActions">
			<div class="col-md-2">
				<button class="btn warning-btn" @click="obtener_proveedores">Recargar</button>
			</div>
			<div class="col-md-2">
				<button v-if="$auth.can('tesoreria','crear_proveedores')" class="btn complementary-btn" @click="crear_proveedor">Nuevo</button>
			</div>
			<div class="col-md-2">
				<button v-if="$auth.can('tesoreria','editar_proveedores')" class="btn secondary-btn" @click="editar_proveedor">Editar</button>
			</div>
		</DataGrid>

		<Modal v-if="mostrar_formulario !== null" class="bpb-modal">
			<h2 class="title">Proveedor</h2>
			<div class="body">
				<div class="row form-group">
					<label for="empresa" class="col-form-label col-sm-3 col-lg-2">Empresa</label>
					<div class="col-sm-9 col-lg-10">
						<select v-model="proveedor.empresa_id" name="empresa" id="empresa" class="form-control">
							<option v-for="empresa in empresas" :value="empresa.id">{{ empresa.empresa }}</option>
						</select>
					</div>
				</div>
				<div class="row form-group">
					<label for="nombre" class="col-form-label col-sm-3 col-lg-2">Nombre</label>
					<div class="col-sm-9 col-lg-10">
						<input v-model="proveedor.nombre" type="text" name="nombre" id="nombre" class="form-control" autocomplete="off">
					</div>
				</div>
				<div class="row form-group">
					<label for="descripcion" class="col-form-label col-sm-3 col-lg-2">Descripción</label>
					<div class="col-sm-9 col-lg-10">
						<input v-model="proveedor.descripcion" type="text" name="descripcion" id="descripcion" class="form-control" autocomplete="off">
					</div>
				</div>
				<div class="row form-group">
					<label for="email" class="col-form-label col-sm-3 col-lg-2">Email</label>
					<div class="col-sm-9 col-lg-10">
						<input v-model="proveedor.email" type="email" name="email" id="email" class="form-control" autocomplete="off">
					</div>
				</div>
				<div class="row form-group">
					<label for="estatus" class="col-form-label col-sm-3 col-lg-2">Estatus</label>
					<div class="col-sm-9 col-lg-10">
						<select v-model="proveedor.estatus" name="estatus" id="estatus" class="form-control">
							<option value="1">Activo</option>
							<option value="0">Inactivo</option>
						</select>
					</div>
				</div>
				<br />
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-md-3">
							<button class="btn principal-btn" @click="guardar_proveedor">Guardar</button>
						</div>
						<div class="col-md-3">	
							<button class="btn complementary-btn" @click="cancelar_proveedor">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGrid'
	import Modal from '@/components/Modal'

	import api from '@/apps/tesoreria/api/tesoreria'

	export default {
		components: {
			DataGrid, Modal
		}
		,data: () => ({
			dataGridConfig: {
				name: 'proveedores'
				,cols: {
					empresa_id: 'Empresa'
					,nombre: 'Nombre'
					,email: 'Email'
					,descripcion: 'Descripción'
					,estatus: 'Estatus'
					,created_at: 'Fecha creación'
				}
				,filters: {
					cols: {
						id: 'ID'
						,empresa: {
							text: 'Empresa'
							,options: {}
						}
						,nombre: 'Nombre'
						,email: 'Email'
						,descripcion: 'Descripción'
						,estatus: {
							text: 'Estatus'
							,options: {
								1: 'Activo'
								,0: 'Inactivo'
							}
						}
					}
				}
				,paginator: {
					pagina_actual: 1
					,total_registros: 1
					,registros_por_pagina: 20
				}
				,mutators: {
					empresa_id: function(val,row) {
						return row.empresa.empresa;
					}
					,estatus: function(val) {
						return val == 1 ? 'Activo' : 'Inactivo'
					}
				}
			}
			,options: {
				page: 1
				,order_col: 'id'
				,order_dir: 'desc'
				,limit: 20
				,filters: []
			}
			,proveedores: []
			,proveedor: {
				account_id: null
				,empresa_id: null
				,nombre: null
				,descripcion: null
				,usuario: null
				,estatus: 1
			}
			,seleccionados: []
			,seleccionar: false
			,empresas: []
			,mostrar_formulario: null
		})
		,created: function() {
			this.obtener_proveedores();
			this.obtener_empresas();
		}
		,methods: {
			obtener_proveedores: function() {
				api.obtener_proveedores(this.options)
				.then(res => {
					this.$log.info('res', res);

					this.proveedores = res.data.data;

					this.dataGridConfig.paginator.pagina_actual = res.data.current_page;
					this.dataGridConfig.paginator.total_registros = res.data.total;
					this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.data.per_page);
				})
				.catch(err => {
					this.$log.info('err', err);
					this.$helper.showAxiosError(err, 'Error al obtener los proveedores');
				})
			}
			,obtener_empresas: function() {
				api.listar_empresas()
				.then(res => {
					this.$log.info('res', res);
					this.empresas = res.data;
					for(let i=0; i<this.empresas.length; i++) {
						this.dataGridConfig.filters.cols.empresa.options[this.empresas[i].id] = this.empresas[i].empresa;
					}
				})
				.catch(err => {
					this.$log.info('err', err);
					this.$helper.showAxiosError(err,'Error');
				})
			}
			,dataGridActions: function(type, data) {
				if (type == 'options') {
					this.$log.info('options', data);
					this.options = data;
					this.obtener_proveedores();
					this.seleccionar = false;
				}else {
					this.seleccionados = data;
					this.seleccionar = true;
				}
			}
			,cancelar_proveedor: function() {
				this.proveedor = {
					account_id: null
					,empresa_id: null
					,nombre: null
					,estatus: 1
				}

				this.seleccionar = false;

				this.mostrar_formulario = null;
			}
			,crear_proveedor: function() {
				this.proveedor.empresa_id = this.empresas[0].account_id;
				this.$log.info('proveedor: ', this.proveedor);
				this.mostrar_formulario = 1;
			}
			,editar_proveedor: function() {
				if (this.seleccionados.length > 0) {
					this.proveedor = this.seleccionados[0];
					this.$log.info('proveedor: ', this.proveedor);
					this.mostrar_formulario = 2;
				}else {
					this.$notify({
						group: 'alert'
						,type: 'error'
						,title: 'Error editar proveedor'
						,text: 'Necesitas seleccionar un proveedor'
						,duration: 5000
					});
				}
			}
			,guardar_proveedor: function() {
				if (this.mostrar_formulario == 1) {
					api.crear_proveedor(this.proveedor)
					.then(res => {
						this.$log.info('res', res);

						this.cancelar_proveedor();
						this.obtener_proveedores();
					})
					.catch(err => {
						this.$log.info('err', err);
						this.$helper.showAxiosError(err,'Error');
					})
				}else {
					api.editar_proveedor(this.proveedor.id, this.proveedor)
					.then(res => {
						this.$log.info('res', res);

						this.cancelar_proveedor();
						this.obtener_proveedores();
					})
					.catch(err => {
						this.$log.info('err', err);
						this.$helper.showAxiosError(err,'Error');
					})
				}
			}
		}
	}
</script>